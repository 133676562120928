import * as React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";

import Layout from "../components/layout";
import NewsItem from "../components/news-item";

const moreBtn = css`
  border: 1px solid #ccc;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  appearance: none;
  padding: 0.25em 1em;
`;

const btnContainer = css`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const PAR_VIEW = 10;
const pageTitle = "News show more";

const News = ({ data }) => {
  const allNews = data.allMicrocmsNews.nodes;

  // allNewsを分割してリスト化
  const [list, setList] = React.useState([...allNews.slice(0, PAR_VIEW)]);
  // 読込みトリガー
  const [loadMore, setLoadMore] = React.useState(false);
  // 読込む分がまだあるか
  const [hasMore, setHasMore] = React.useState(allNews.length > PAR_VIEW);

  // moreボタンクリック
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  React.useEffect(() => {
    if (loadMore && hasMore) {
      setList((list) => {
        // リストの残り数
        const currentLength = list.length;
        // 全部はリストの残りより少ないか まだあるか
        const isMore = currentLength < allNews.length;
        // isMore = true で次の表示分配置
        const nextResults = isMore
          ? allNews.slice(currentLength, currentLength + PAR_VIEW)
          : [];
        return [...list, ...nextResults];
      });
      setLoadMore(false);
    }
  }, [loadMore, hasMore, allNews]);

  React.useEffect(() => {
    // リストが更新された時残りはどうなっているか
    const isMore = list.length < allNews.length;
    setHasMore(isMore);
  }, [list, allNews]);

  return (
    <Layout pageTitle={pageTitle}>
      {list.map((node) => {
        return <NewsItem postData={node} />;
      })}
      <div css={btnContainer}>
        {hasMore ? (
          <button type="button" onClick={handleLoadMore} css={moreBtn}>
            もっと見る
          </button>
        ) : (
          <p>もうないよ</p>
        )}
      </div>
    </Layout>
  );
};

export default News;

export const query = graphql`
  query {
    allMicrocmsNews(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        publishedAt(formatString: "YYYY.MM.DD")
        newsId
        category {
          newsCategoryId
          name
          slug
        }
      }
      totalCount
    }
  }
`;
